<template>
  <div style="width: 100%" class="mb-6">
    <v-row class="ml-1">
      <v-col :cols="hasConfigPhonetic ? 12 : 7">
        <template v-for="(option, i) in optionMatching">
          <div style="display: flex" :key="i" class="mb-1">
            <v-text-field
                :ref="'option-matching-'+i"
                :label="'Option' + (i + 1)"
                v-model="option.value"
                outlined dense
            ></v-text-field>
            <v-btn
                v-if="hasConfigPhonetic"
                outlined color="#039BE5"
                class="mt-2 ml-1" small
                @click="replacePinyinByTextSelectedViaInputOptionRef('option-matching-'+i, i)"
            >
              Lấy pinyin
            </v-btn>
            <v-btn class="mt-2 mx-1"
                   small
                   outlined color="red"
                   @click="deleteOptionMatching(i)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <div style="width: 50%; border: 1px solid grey" class="ml-1 rounded" v-if="hasConfigPhonetic">
              <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
              <div
                  class="rounded px-1 py-2 container-pinyin-preview"
                  v-html="option.value">
              </div>
            </div>
          </div>
        </template>
      </v-col>
      <v-col cols="12">
        <v-btn large @click="addOptionMatching">
          <v-icon>mdi-plus</v-icon> Add Option
        </v-btn>
      </v-col>
      <v-col cols="12" class="my-4">
        <template v-for="(correct, i) in correctMatching">
          <v-row style="display: flex" :key="i" class="mb-2">
            <v-col cols="4" class="d-flex pt-0 pb-0">
              <p class="text-h5 mt-2 mr-2" style="color: #6495ed">
                {{ i | getAlphabetCharacterFromIndex }}
              </p>
              <v-select
                  :items="optionMatching"
                  item-value="value"
                  item-text="value"
                  label="Select Correct"
                  required dense outlined
                  v-model="correct.index"
              ></v-select>
            </v-col>
            <v-col :cols="hasConfigPhonetic ? 6 : 7" class="pt-0 pb-0">
              <v-text-field
                  :label="'Question' + (i + 1)"
                  required dense outlined
                  v-model="correct.value"
                  :ref="'question-matching-'+i"
              >
              </v-text-field>
            </v-col>
            <v-col :cols="hasConfigPhonetic ? 2 : 1" class="pt-0 pb-0">
              <v-btn
                  v-if="hasConfigPhonetic"
                  outlined color="#039BE5" small
                  class="mt-0 mr-2"
                  @click="replacePinyinByTextSelectedViaInputOptionRef('question-matching-'+i, i)"
              >
                lấy pinyin
              </v-btn>
              <v-btn class="mt-0" @click="deleteQuestionMatching(i)" small color="red" outlined>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <template v-if="hasConfigPhonetic">
              <v-col cols="4" class="py-0"></v-col>
              <v-col cols="8" class="pt-0">
                <div style="width: 100%; border: 1px solid grey" class="rounded">
                  <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
                  <div
                      class="rounded px-1 py-2 container-pinyin-preview"
                      v-html="correct.value">
                  </div>
                </div>
              </v-col>
            </template>
          </v-row>
        </template>
      </v-col>
      <v-col cols="12">
        <v-btn large @click="addCorrectMatching">
          <v-icon>mdi-plus</v-icon> Add Question
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import phoneticSupport from "../Support/Question/phoneticSupport";

export default {
  name: "Matching",
  mixins: [
    phoneticSupport,
  ],
  props: {
    optionMatchingDataProp: {
      type: Array,
      default: () => [{ value: ""}, { value: ""}],
    },
    correctMatchingDataProp: {
      type: Array,
      default: () => [{ value: "", index: "" }],
    },
    type_form_exercise: {
      type: String,
      default: ''
    },
    hasConfigPhonetic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    optionMatching: {
      get() {
        return this.optionMatchingDataProp;
      },
      set(value) {
        this.$emit("setOptionMatching", value);
      },
    },
    correctMatching: {
      get() {
        return this.correctMatchingDataProp;
      },
      set(value) {
        this.$emit("setCorrectMatching", value);
      },
    },
  },
  methods: {
    messError(mess = '') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    deleteOptionMatching(i) {
      if (this.optionMatching.length <= 1) {
        this.messError("Phải có tối thiểu 1 option !!")
        return;
      }
      this.optionMatching.splice(i, 1);
    },
    addOptionMatching() {
      let dataOptionMatching = { value: ""};
      this.optionMatching.push(dataOptionMatching).length;

      let dataCorrectMatching = {
        index: "",
        value: "",
      };
      this.correctMatching.push(dataCorrectMatching);
    },
    deleteQuestionMatching(i) {
      if (this.correctMatching.length <= 1) {
        this.messError('Phải có tối thiểu 1 question !!');
        return;
      }
      this.correctMatching.splice(i, 1);
    },
    addCorrectMatching() {
      let dataCorrectMatching = {
        value: "",
        index: "",
      };
      this.correctMatching.push(dataCorrectMatching);
    },
    async replacePinyinByTextSelectedViaInputOptionRef(inputRef = '', indexOption) {
      if (!inputRef) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refOption = this.$refs[inputRef][0].$refs;
      let input = refOption.input;

      const textSelected = input.value.substring(
          input.selectionStart,
          input.selectionEnd,
      );

      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      const starSto = input.value.substring(0, input.selectionStart);
      const endSto = input.value.substring(input.selectionEnd, input.value.length);

      const dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      if (inputRef.includes('option-matching')) {
        this.optionMatching[indexOption].value =
            starSto +
            " " + dataPinyin + " " +
            endSto
        ;
      } else {
        this.correctMatching[indexOption].value =
            starSto +
            " " + dataPinyin + " " +
            endSto
        ;
      }
      // this.optionMatching[indexOption].value =
      //     starSto +
      //     " " + dataPinyin + " " +
      //     endSto
      // ;

      setTimeout(() => {
        input.focus();
        input.selectionStart = starSto.length + dataPinyin.length +2;
        input.selectionEnd = starSto.length + dataPinyin.length +2;
      }, 10);

    },
  }
}
</script>

<style scoped>
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>