<template>
  <v-row class="m-0 p-0">
    <v-col cols="8" class="pl-0">
      <label v-if="!$utils.isValueInvalid(label)" style="font-size: 16px">{{label}}</label>
      <Editor v-model.trim="inputTitleQuestion" :ref="nameInputRef"></Editor>

      <v-tooltip bottom color="#181c32f0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="getPinyinFromChineseTextSelectedToRefInputEditor(nameInputRef)"
              class="mt-2 font-weight-bold"
              color="blue darken-1"
              outlined
              v-bind="attrs" v-on="on"
          >Thêm phiên âm
          </v-btn>
        </template>
        <div style="max-width: 500px; color: white">
          Thầy cô vui lòng bổ sung dấu cách giữa các từ có nghĩa trước khi thêm phiên âm để đảm bảo phiên âm hiển thị đúng vị trí các từ. Sau khi thêm phiên âm, thầy cô có thể điều chỉnh pinyin bằng cách edit nội dung pinyin giữa các thẻ &lt;/rt&gt;
        </div>
      </v-tooltip>

    </v-col>
    <v-col cols="4" class="pl-0 pr-0">
      <div class="border rounded-lg" :class="{ 'mt-6': !$utils.isValueInvalid(label) }">
        <p
            style="background-color: #fafafa"
            class="text-h6 py-5 text-center mb-0">Preview</p>
        <div
            class="p-2 overflow-auto container-pinyin-preview"
            style="height: 200px"
            v-html="decodeHTMLForChineseCharacter(inputTitleQuestion)"></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import phoneticSupport from "./phoneticSupport";

export default {
  name: "PhoneticInput",
  mixins: [
      phoneticSupport,
  ],
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    nameInputRef: {
      type: String,
      default: "",
    },
  },
  components: {
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  computed: {
    inputTitleQuestion: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>