<template>
  <div style="width: 100%" class="mb-6">
    <v-row class="ml-1">
      <v-col cols="12" class="pb-0">
        <p class="text-body-1 grey--text lighten-1 font-weight-medium">Matching Pair là một dạng bài tập matching, trong đó, mỗi Option chỉ được match duy nhất với 1 Question. Do vậy, khi Add Question, hệ thống sẽ tự động thêm 1 ô nhập Option để đảm bảo số Question và Option là bằng nhau và ngược lại</p>
        <p class="text-body-1 red--text font-weight-medium">Lưu ý: hiện tại mỗi ô nhập chỉ cho phép 1 loại dữ liệu (text/ ảnh/ audio), vui lòng không nhập 2 loại dữ liệu vào cùng 1 ô. Hệ thống sẽ không cover hiển thị cho trường hợp này!</p>
      </v-col>

      <v-col cols="6" class="p-0">
        <v-row>
          <v-col cols="3" class="p-0">
            <span class="black--text font-weight-bold text-h6 float-right mr-2 mt-2">Questions: </span>
          </v-col>
          <v-col cols="7" class="p-0">
            <v-select
                v-model="matching_pair_question_type"
                :items="question_matching_pair_types"
                item-text="label"
                item-value="value"
                placeholder="Chọn loại dữ liệu"
                outlined dense
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0 px-8" v-if="matching_pair_question_type === 2">
            <p class="text-body-1 grey--text lighten-1 font-weight-medium">Cần nhập ảnh có tỷ lệ dài:rộng là 3:2, định dạng PNG/JPG, mỗi ô chỉ nhập 1 ảnh. Dung lượng ảnh không quá 2MB</p>
          </v-col>
        </v-row>
        <template v-for="(data_question, index) in data_question_matching_pair_input">
          <v-row :key="index">
            <v-col cols="1">
              <span class="black--text font-weight-bold text-h6 float-right mt-5">{{index+1}}.</span>
            </v-col>
            <v-col :cols="hasConfigPhonetic ? 8 : 9">
              <template v-if="matching_pair_question_type === 1">
                <EditorOnlyText placeholder="Nhập nôi dung" v-model="data_question.value" :ref="'matching-pair-editor-'+index" />
              </template>
              <template v-if="matching_pair_question_type === 2">
                <EditorOnlyImage placeholder="Nhập nôi dung" v-model="data_question.value" />
              </template>
            </v-col>
            <v-col cols="1" class="px-0" v-if="hasConfigPhonetic">
              <v-btn
                  outlined color="#039BE5"
                  class="mt-5" small text
                  @click="getPinyinFromChineseTextSelectedToRefInputEditorOnlyText('matching-pair-editor-'+index)"
              >
                pinyin
              </v-btn>
            </v-col>
            <v-col :cols="hasConfigPhonetic ? 1 : 2">
              <v-hover
                  v-slot="{ hover }"
              >
                <v-icon
                    @click="deleteItemQuestionMatchingPair(index)"
                    class="mt-5 cursor-pointer" :color="hover ? 'red' : 'grey' ">
                  mdi-delete
                </v-icon>
              </v-hover>
            </v-col>
            <template v-if="hasConfigPhonetic">
              <v-col cols="12" class="m-0 p-0"></v-col>
              <v-col cols="1"></v-col>
              <v-col cols="10" style="border: 1px solid grey" class="p-0 ml-2 rounded">
                <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
                <div
                    class="rounded px-1 py-2 container-pinyin-preview"
                    v-html="decodeHTMLForChineseCharacter(data_question.value)">
                </div>
              </v-col>
            </template>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12">
            <v-btn large @click="btnAddQuestion()">
              <v-icon>mdi-plus</v-icon> Add Question
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" class="p-0">
        <v-row>
          <v-col cols="3" class="p-0">
            <span class="black--text font-weight-bold text-h6 float-right mr-2 mt-2">Options: </span>
          </v-col>
          <v-col cols="7" class="p-0">
            <v-select
                v-model="matching_pair_option_type"
                :items="option_matching_pair_types"
                item-text="label"
                item-value="value"
                placeholder="Chọn loại dữ liệu"
                outlined dense
            ></v-select>
          </v-col>
        </v-row>
        <template v-for="(data_option, index) in data_option_matching_pair_input">
          <v-row :key="index">
            <v-col cols="1">
<!--              <span class="black&#45;&#45;text font-weight-bold text-h6 float-right mt-5">{{index+1}}.</span>-->
              <span class="black--text font-weight-bold text-h6 float-right mt-5">{{index|getAlphabetCharacterFromIndex}}.</span>
            </v-col>
            <v-col :cols="hasConfigPhonetic ? 8 : 9">
              <EditorOnlyText
                  placeholder="Nhập nôi dung"
                  v-model="data_option.value"
                  @input="eventChangeValueOption(data_option)"
                  :ref="'matching-pair-option-editor-'+index"
              />
            </v-col>
            <v-col cols="1" class="px-0" v-if="hasConfigPhonetic">
              <v-btn
                  outlined color="#039BE5"
                  class="mt-5" small text
                  @click="getPinyinFromChineseTextSelectedToRefInputEditorOnlyText('matching-pair-option-editor-'+index)"
              >
                pinyin
              </v-btn>
            </v-col>
            <v-col :cols="hasConfigPhonetic ? 1 : 2">
              <v-hover
                  v-slot="{ hover }"
              >
                <v-icon
                    @click="deleteItemOptionMatchingPair(index)"
                    class="mt-5 cursor-pointer" :color="hover ? 'red' : 'grey' ">
                  mdi-delete
                </v-icon>
              </v-hover>
            </v-col>
          <template v-if="hasConfigPhonetic">
            <v-col cols="12" class="m-0 p-0"></v-col>
            <v-col cols="1"></v-col>
            <v-col cols="10" style="border: 1px solid grey" class="p-0 ml-2 rounded">
              <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
              <div
                  class="rounded px-1 py-2 container-pinyin-preview"
                  v-html="decodeHTMLForChineseCharacter(data_option.value)">
              </div>
            </v-col>
          </template>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12">
            <v-btn large @click="btnAddOption()">
              <v-icon>mdi-plus</v-icon> Add Option
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="8" class="pb-0">
        <p class="black--text font-weight-bold text-h6 mt-4 mb-1">Đáp án </p>
        <p class="text-body-1 grey--text lighten-1 font-weight-medium">Chọn 1 option của cột 2 tương ứng là đáp án cho mỗi question ở cột 1</p>

        <template v-for="(correct_obj, index) in data_correct_matching_pair">
          <v-row :key="index">
            <v-col cols="3" class="">
              <p class="black--text font-weight-medium text-h6 mt-4 mb-1">Question {{index+1}} </p>
            </v-col>
            <v-col cols="9" class="">
              <v-autocomplete
                  @change="(event) => eventChangeOptionCorrect(event, index)"
                  :value="correct_obj.option.value"
                  :items="data_option_matching_pair_input"
                  outlined dense clearable
              >
                <template v-slot:item="{item}">
                  <div v-html="item.value"></div>
                </template>
                <template v-slot:selection="{ item, index }">
                  <template v-if="item.value">
                    <div v-html="item.value"></div>
                  </template>
                  <template v-else>
                    <span class="grey--text">Chọn option tương ứng cho question</span>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </template>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import EditorOnlyText from "../../../../../components/ckeditor5/EditorOnlyText";
import EditorOnlyImage from "../../../../../components/ckeditor5/EditorOnlyImage";
import phoneticSupport from "../Support/Question/phoneticSupport";
export default {
  name: "MatchingPair",
  mixins: [
    phoneticSupport,
  ],
  components: {
    EditorOnlyImage,
    EditorOnlyText,
  },
  props: {
    hasConfigPhonetic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      question_matching_pair_types: [
        {value: 1, label: 'Text'},
        {value: 2, label: 'Image'},
        // {value: 3, label: 'Audio'},
        // {value: 4, label: 'Text And Image'},
        // {value: 5, label: 'Text And Audio'},
      ],

      option_matching_pair_types: [
        {value: 1, label: 'Text'},
      ],

    }
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    data_question_matching_pair_input: {
      get() {
        return this.$store.state.contentTestBuilderStore.data_question_matching_pair_input;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDataQuestionMatchingPairInput", value);
      },
    },
    matching_pair_question_type: {
      get() {
        return this.$store.state.contentTestBuilderStore.matching_pair_question_type;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setMatchingPairQuestionType", value);
      },
    },
    data_option_matching_pair_input: {
      get() {
        return this.$store.state.contentTestBuilderStore.data_option_matching_pair_input;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDataOptionMatchingPairInput", value);
      },
    },
    matching_pair_option_type: {
      get() {
        return this.$store.state.contentTestBuilderStore.matching_pair_option_type;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setMatchingPairOptionType", value);
      },
    },
    data_correct_matching_pair: {
      get() {
        return this.$store.state.contentTestBuilderStore.data_correct_matching_pair;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDataCorrectMatchingPair", value);
      },
    },
  },
  methods: {
    btnAddQuestion() {
      let maxQuestion = 8;
      if (this.data_question_matching_pair_input.length > (maxQuestion - 1)) {
        this.messError('Chỉ được phép tạo tối đa '+maxQuestion+' question');
        return;
      }

      let question = this.addQuestion();
      let option = this.addOption();

      let optionCp = JSON.parse(JSON.stringify(option))
      optionCp.offset = null;
      this.addCorrectMatchingPair(question, optionCp);
    },
    addQuestion() {
      let dataQues = {
        id: null,
        value: null,
        offset: this.data_question_matching_pair_input[this.data_question_matching_pair_input.length -1].offset +1,
      }
      this.data_question_matching_pair_input.push(dataQues)
      return dataQues;
      // let dataOption = {
      //   value: null,
      //   uuid: null,
      //   offset: null,
      // }
      // this.data_correct_matching_pair.push({
      //   question: dataQues,
      //   option: dataOption,
      // });
    },
    addCorrectMatchingPair(question, option) {
      this.data_correct_matching_pair.push({
        question,
        option,
      });
    },
    deleteItemQuestionMatchingPair(index) {
      let minQuestion = 2;
      if (this.data_question_matching_pair_input.length <= minQuestion) {
        this.messError('Phải có tối thiểu '+minQuestion+' question');
        return
      }
      this.data_question_matching_pair_input.splice(index, 1);
      this.data_correct_matching_pair.splice(index, 1);
    },
    btnAddOption() {
      let maxOption = 8;
      if (this.data_option_matching_pair_input.length > (maxOption - 1)) {
        this.messError('Chỉ được phép tạo tối đa '+maxOption+' option');
        return
      }
      let option = this.addOption();
      let question = this.addQuestion();

      let optionCp = JSON.parse(JSON.stringify(option))
      optionCp.offset = null;
      this.addCorrectMatchingPair(question, optionCp);
    },
    addOption() {
      let dataOption = {
        value: null,
        uuid: null,
        offset: this.data_option_matching_pair_input[this.data_option_matching_pair_input.length -1].offset +1
      }
      this.data_option_matching_pair_input.push(dataOption)
      return dataOption;
    },
    deleteItemOptionMatchingPair(index) {
      let minOption = 2;
      if (this.data_option_matching_pair_input.length <= minOption) {
        this.messError('Phải có tối thiểu '+minOption+' option');
        return
      }
      let isOptionSelected = this.isOptionSelectedInListCorrect(this.data_option_matching_pair_input[index]);
      if (isOptionSelected) {
        this.messError('Option đã được chọn ở mục đáp án');
        return;
      }

      this.data_option_matching_pair_input.splice(index, 1);
    },
    isOptionSelectedInListCorrect(option) {
      let flat = false;
      for (let iCorrect = 0; iCorrect < this.data_correct_matching_pair.length; iCorrect++) {
        if (
            this.data_correct_matching_pair[iCorrect].option.value &&
            this.data_correct_matching_pair[iCorrect].option.value === option.value
        ) {
          flat = true;
        }
      }
      return flat;
    },
    messError(mess = '') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    isDuplicateCorrectRule(value, indexCorrectSelection) {
      let cp_data_correct = JSON.parse(JSON.stringify(this.data_correct_matching_pair));

      cp_data_correct.splice(indexCorrectSelection, 1);
      let checkData = cp_data_correct.filter((obj_correct) => {
        return obj_correct.option.value === value && value;
      });

      return checkData.length > 0;
    },
    eventChangeOptionCorrect(e, indexCorrect) {
      console.log(e, indexCorrect);
      if (this.isDuplicateCorrectRule(e, indexCorrect)) {
        this.messError('Đáp án này đã được chọn question khác');
        this.data_correct_matching_pair[indexCorrect].option = {value: null, offset: null, uuid : null};
        return;
      }
      let optionFilters = this.data_option_matching_pair_input.filter((obj_option) => {
        return obj_option.value === e;
      });

      let objOption = {value: null, offset: null, uuid : null};
      if (optionFilters.length > 0) {
        objOption = optionFilters[0];
      }
      this.data_correct_matching_pair[indexCorrect].option =  JSON.parse(JSON.stringify(objOption));
    },
    eventChangeValueOption(option) {
      let optionCp = JSON.parse(JSON.stringify(option));

      for (let iCorrect = 0; iCorrect < this.data_correct_matching_pair.length; iCorrect++) {
        if(optionCp.offset === this.data_correct_matching_pair[iCorrect].option.offset) {
          this.data_correct_matching_pair[iCorrect].option = optionCp;
        }
      }

    }
  }
}
</script>

<style scoped>
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>